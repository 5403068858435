<template>
    <div class="popular">

        <h2>POPULAR DRINKS</h2>
        <div class="popular-lamp">
            <router-link to="/i" class="links">
                <div class="card">
                    <img src="../assets/s7.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">PEPSI</h3>
                        <p class="lamp-description">Soft Drink</p>
                        <p class="lamp-price">₦399.99</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/ii" class="links">
                <div class="card" >
                    <img src="../assets/s3.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">COCACOLA</h3>
                        <p class="lamp-description">Soft Drink</p>
                        <p class="lamp-price">₦399.99</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iii" class="links">
                <div class="card" >
                    <img src="../assets/j6.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">5ALIVE PULPY</h3>
                        <p class="lamp-description">Fruit Juice</p>
                        <p class="lamp-price">₦999.99</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/iv" class="links">
                <div class="card" >
                    <img src="../assets/j3.jpg" alt="lamp" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">CHIVITA ACTIVE</h3>
                    <p class="lamp-description">Fruit Juice</p>
                    <p class="lamp-price">₦999.99</p>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="popular-lamp">

            <router-link to="/v" class="links">
                <div class="card" >
                <img src="../assets/b6.jpg" alt="lamp" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">TROPHY</h3>
                    <p class="lamp-description">Beer</p>
                    <p class="lamp-price">₦849.99</p>
                </div>
            </div>
            </router-link>
            
            <router-link to="/vi" class="links">
                <div class="card" >
                    <img src="../assets/b5.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">GOLDBERG</h3>
                        <p class="lamp-description">Beer</p>
                        <p class="lamp-price">₦849.99</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/vii" class="links">
                <div class="card" >
                    <img src="../assets/w6.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">HENNESSY</h3>
                        <p class="lamp-description">Whiskey</p>
                        <p class="lamp-price">₦79,999</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xvi" class="links">
                <div class="card" >
                    <img src="../assets/w1.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">BEST </h3>
                        <p class="lamp-description">Whiskey</p>
                        <p class="lamp-price">₦17,999</p>
                    </div>
                </div>
            </router-link>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'PopularPage',
    }
</script>

<style scoped>
.popular{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    padding: 100px 0px;
}

.popular h2{
    color: black;
    padding: 0px 0px;
    font-family: 'Montserrat', sans-serif;
}

.popular-lamp{
    display: flex;
    justify-content: center;
}

.card {
    width: 250px; 
    margin: 10px; 
    background-color:  rgba(255, 215, 0); 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    text-align: center;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    border: 1px solid rgb(217, 217, 217);
}

.card:hover {
    transform: scale(1.05); 
}

.card img {
    width: 250px;
    height: 250px; 
    object-fit: cover; 
}
  
  
.lamp-details {
    color: black;
    text-align: left;
    padding: 10px;
}
  
.lamp-title {
    font-size: 20px;
    margin-bottom: 5px; 
    font-weight: 700;
}
  
.lamp-description {
    font-size: 14px; 
    margin-bottom: 10px; 
}
  
.lamp-price {
    font-weight: bold; 
    margin-bottom: 5px; 
    font-size: 20px;
}

.links {
    text-decoration: none;
}

@media (max-width: 768px) {
  .popular-lamp {
    flex-wrap: wrap; /* Wrap cards to stack vertically */
    justify-content: center; /* Center the cards horizontally */
  }

  .card {
    margin: 10px 0; /* Adjust margin for vertical stacking */
  }
}


</style>