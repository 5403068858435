<template>
  <div class="contact-head">
    <h1>Contact Us</h1>
  </div>
  <div class="contact">
    <div class="contact-info">
      <div class="get-container">
        <h2>Get In Touch</h2>
        <p>
          At Liquid Lux Drink Store, we’re dedicated to offering an exceptional beverage experience. Whether you have questions about our products, need assistance with your order, or want to share your feedback, we’re here to help! Reach out to us via email at <a href="mailto:support@liquidlux.com">support@liquidlux.com</a>. You can also fill out our contact form below.
        </p>
        <div class="contact-details">
          <ul>
            <li><i class="fa-solid fa-phone"></i> +234 9151726911</li>
            <li><i class="fa-brands fa-whatsapp"></i> +234 8171873887</li>
            <li><i class="fa-regular fa-envelope"></i> <a href="mailto:support@liquidlux.com">support@liquidlux.com</a></li>
            <li><i class="fa-solid fa-shop"></i> 43, Ikorodu Club Avenue, Aga, Ikorodu, Lagos, Nigeria</li>
          </ul>
        </div>
      </div>

      <div class="form-container">
        <form class="contact-form">
          <div class="form-group">
            <label for="name">Name:</label>
            <input type="text" id="name" name="name" required>
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" name="email" required>
          </div>
          <div class="form-group">
            <label for="subject">Address:</label>
            <input type="text" id="subject" name="subject" required>
          </div>
          <div class="form-group">
            <label for="message">Message:</label>
            <textarea id="message" name="message" rows="5" required></textarea>
          </div>
          <button type="submit">Send Now</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactPage',
};
</script>

<style scoped>
.contact-head {
  text-align: center;
  color: white;
  margin-bottom: 20px;
  padding: 100px 0;
  font-size: 60px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/cus.jpeg');
  background-size: cover;
  background-position: center;
}

.contact {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.contact-info {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 40px 0;
}

.get-container {
  flex: 1;
  margin: 20px;
}

.contact-details ul {
  list-style: none;
  padding-left: 0;
  font-size: 20px;
}

.contact-details ul li {
  padding: 10px 0;
}

.contact-details a {
  color: inherit;
  text-decoration: none;
}

.contact-details i:hover {
  color: rgb(176, 174, 174);
}

.form-container {
  flex: 1;
  margin: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
}

.form-group textarea {
  height: 100px;
}

.contact-form button {
  background-color: rgba(255, 215, 0);
  border: none;
  color: black;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}

.contact-form button:hover {
  color: black;
  background-color: white;
  border: 1px solid black;
}
</style>
