<template>
    <div class="lamps-header">
        <h1>WHISKEY</h1>
    </div>
    <div class="popular">

        <div class="popular-lamp">

            <router-link to="/xviii" class="links">
                <div class="card" >
                <img src="../assets/w3.jpg" alt="lamp" class="lamps" >
                <div class="lamp-details">
                    <h3 class="lamp-title">JAMESON</h3>
                    <p class="lamp-description">Whiskey</p>
                        <p class="lamp-price">₦31,999</p>
                </div>
            </div>
            </router-link>
            
            <router-link to="/xix" class="links">
                <div class="card" >
                    <img src="../assets/w8.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">MAGIC MOMENT</h3>
                        <p class="lamp-description">Whiskey</p>
                        <p class="lamp-price">₦4,999</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xx" class="links">
                <div class="card" >
                    <img src="../assets/w7.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">SKIRT</h3>
                        <p class="lamp-description">Whiskey</p>
                        <p class="lamp-price">₦7,999</p>
                    </div>
                </div>
            </router-link>

            <router-link to="/xvi" class="links">
                <div class="card" >
                    <img src="../assets/w1.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">BEST </h3>
                        <p class="lamp-description">Whiskey</p>
                        <p class="lamp-price">₦17,999</p>
                    </div>
                </div>
            </router-link>
            
            <router-link to="/vii" class="links">
                <div class="card" >
                    <img src="../assets/w6.jpg" alt="lamp" class="lamps" >
                    <div class="lamp-details">
                        <h3 class="lamp-title">HENNESSY</h3>
                        <p class="lamp-description">Whiskey</p>
                        <p class="lamp-price">₦79,999</p>
                    </div>
                </div>
            </router-link>
        </div>

    </div>


</template>

<script>
    export default {
        name: "ContactPage"
    }
</script>

<style scoped>
.lamps-header {
    text-align: center;
    color: white;
    padding: 100px 0px;
    font-size: 60px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url('@/assets/wb.jpg');
    background-size: cover;
    background-position: center;
}
.popular{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    padding: 100px 0px;
}

.popular-lamp{
    display: flex;
    justify-content: center;
}

.card {
    width: 250px; 
    margin: 10px; 
    background-color:rgba(255, 215, 0); 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    text-align: center;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
    border: 1px solid rgb(217, 217, 217);
}

.card:hover {
    transform: scale(1.05); 
}

.card img {
    width: 250px;
    height: 250px; 
    object-fit: cover; 
}
  
  
.lamp-details {
    color: black;
    text-align: left;
    padding: 10px;
}
  
.lamp-title {
    font-size: 20px;
    margin-bottom: 5px; 
    font-weight: 700;
}
  
.lamp-description {
    font-size: 14px; 
    margin-bottom: 10px; 
}
  
.lamp-price {
    font-weight: bold; 
    margin-bottom: 5px; 
    font-size: 20px;
}

.links {
    text-decoration: none;
}
</style>