<template>
    <div class="about-header">
        <h1>ABOUT US</h1>
    </div>
    <div class="about">
        <div class="text-container">
            <h1>Your Ultimate Online Destination for Premium Beverages</h1>
            <p>Liquid Lux Drink Store was established in 2024 with a clear mission: to be the premier destination for premium and diverse beverages when you need them most. Born out of the founder's own challenge to find a reliable online liquor store, we offer an extensive selection of spirits, wines, and craft beers, all curated to meet your discerning tastes. Whether you’re looking for classic favorites or unique finds, Liquid Lux is your go-to source for top-quality drinks delivered straight to your door.
            </p>
        </div>
        <div class="about-content">
            <div class="image-container">
                <img src="../assets/bee.jpeg" alt="Man with light" width="600px" height="375px">
            </div>
            <div class="text-container">
                <h1>"We’ve got a top-notch crew of beverage experts"</h1>
                <p>At Liquid Lux Drink Store, we’ve assembled a top-notch crew of beverage experts who are passionate about delivering the finest selection of drinks. From rare spirits to popular favorites, our knowledgeable team is dedicated to curating an exceptional range and providing expert recommendations to ensure you always find exactly what you’re looking for.
                </p>
            </div>
        </div>
        <div class="text">
            <h1>“We believe that the comfort of enjoying your favorite drinks at home should come with simplicity and ease, making every sip a part of an exceptional experience.”</h1>
        </div>
        <div class="about-content">
            <div class="text-container">
                <h1>“We prioritize customer retention.”</h1>
                <p>
                    At Liquid Lux Drink Store, we value more than just a sale, We cherish building lasting relationships with our customers. Our dedicated team is committed to ensuring that every experience with us is exceptional, so you keep coming back for your favorite spirits, wines, and craft beers. Your satisfaction is our priority, and we’re here to make sure every order exceeds your expectations.
                </p>
            </div>
            <div class="image-container">
                <img src="../assets/crr.jpg" alt="Man with light" width="600px" height="375px">
            </div>
        </div>
        <div class="about-content">
            <div class="image-container">
                <img src="../assets/css.jpeg" alt="Man with light" width="600px" height="375px">
            </div>
            <div class="text-container">
                <h1>We’re dedicated to our customers</h1>
                <p>
                    We are dedicated to delivering the finest selection of beverages with unwavering commitment to quality, service, and customer satisfaction. Every bottle, every order, and every interaction is handled with care and passion, ensuring that our customers receive the best experience possible. Your enjoyment is our priority, and we are devoted to making every sip memorable.
                </p>
            </div>
        </div>
        <div class="about-content">
            <div class="text-container">
                <h1>Vision</h1>
                <p>
                    To be the leading online liquor store, offering a diverse selection of premium drinks with exceptional customer service, making quality beverages accessible to all, anytime, anywhere.
                </p>
            </div>
            <div class="text-container">
                <h1>Mission</h1>
                <p>
                    Our mission is to provide customers with a seamless and enjoyable shopping experience by offering a wide range of high-quality alcoholic beverages, swift delivery, and personalized recommendations. We are committed to excellence, convenience, and customer satisfaction, making every occasion memorable.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutPage',
    }
</script>

<style scoped>
   .about {
        max-width: 1200px;
        margin: 0 auto;
        padding: 20px;
        color: black;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
    }
   .about-header {
        text-align: center;
        color: white;
        margin-bottom: 20px;
        padding: 100px 0px;
        font-size: 60px;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/au.jpg');
        background-size: cover;
        background-position: center;
    }
   .about-content {
        width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 3rem;
        display: flex;
        padding: 40px 0px;
    }
   .image-container {
        flex-basis: 40%;
        margin: 20px;
    }
   .text-container {
        flex-basis: 50%;
        margin: 20px;
    }
   .image-container img {
        width: 600px;
        height: 400px;
    }
    .text h1{
        font-weight: 300;
    }
    .text-container h1 {
        font-weight: 300;
    }
    .text-container h3 {
        font-size: 30px;
    }
    .text-container p {
        font-size: 18px;
    }
</style>
