<template>
    <div class="detail-page">
      <div class="detail-item">
        <img src="../assets/w1.jpg" alt="lamp" class="lamps" >
        <div class="order-details">
          <p class="detail-name">BEST</p>
          <p>Whiskey</p>
          <p class="detail-price">₦17,999</p>
          <router-link to="/order" class="links">
            <button class="add-to-cart">Order Now</button>
          </router-link>
          <ol class="warranty-list">
            <li>Free delivery on all orders over #50,000</li>
            <li>No refund</li>
            <li>Available 24/7</li>
          </ol>
        </div>
      </div>
    </div>
  
  </template>
  
  <script>
      export default {
          name: "ProductVii",
      }
  </script>
  
  <style scoped>
  
  .detail-page {
      display: flex; 
      width: 80%; 
      margin: 0 auto; 
      padding: 20px; 
      border: 1px solid #ddd; 
      margin-top: 100px;
      color: black;
      font-family: 'Montserrat', sans-serif;
  }
    
  .detail-item {
      display: flex;
  }
    
  .lamps {
      width: 50%; 
      height: auto; 
      object-fit: cover; 
  }
    
  .order-details {
      padding-left: 100px; 
      flex: 2; 
      justify-content: center;
      text-align: center;
  }
    
    
  .detail-name {
      font-size: 40px; 
      margin-bottom: 30px; 
  }
    
  .detail-price {
      font-size: 25px;
      padding-top: 30px;
      font-weight: bold; 
      margin-bottom: 30px; 
  }
    
  .add-to-cart {
      color:  black; 
      cursor: pointer; 
      text-align: center;
      background-color: transparent;
      border: 2px solid black;
      border-radius: 100px;
      padding: 15px 20px;
      font-size: 15px;
      text-decoration: none;
  }
  
  .add-to-cart:hover {
      background-color:  rgba(255, 215, 0); 
      color: black;
  }
    
  .warranty-list {
      margin: 0; 
      padding-top: 30px; 
      list-style: disc; 
  }
    
  .warranty-list li {
      margin-bottom: 5px; 
      color: #868585;
  }
  
  .links {
      text-decoration: none;
  }
  </style>